.body-container {
  height: auto;
  width: 100vw;
  background-color: #02081b;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.body-picture {
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: 100% 100%;
}

.body-picture-hexagon {
  display: flex;
  position: relative;
  width: 240px;
  height: 265px;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  background: url("../../img/profile15.jpg") no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: 100% 100%;
}

.body-text-intro {
  font-family: "Raleway";
  margin: 20px 400px;
}

.body-text {
  display: flex;
  flex-direction: column;
  color: white;
  height: 100%;
  background-color: black;
  width: 100%;
  font-family: "Open Sans";
  font-size: 18px;
  align-items: center;
}

.body-text-title {
  color: green;
}

.skill-container-outer {
  margin: 20px 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}

.skill-container {
  display: flex;
  width: auto;
  list-style: none;
}

.skill {
  display: flex;
  justify-content: flex-start;
  margin: 2px;
}

.skill-ul {
  list-style: none;
}

li::before {
  content: "▹";
  padding: 0 12px;
  color: rgb(100, 255, 218);
}

@media only screen and (max-width: 600px) {
  .body-text-intro {
    margin: 20px 50px;
  }

  .skill-container-outer {
    margin: 20px 45px 20px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
  }

  .skill-container {
    justify-content: center;
    width: 158px;
  }

  .body-picture-hexagon {
    width: 180px;
    height: 215px;
  }
}

.body-wrapper {
  display: flex;
  justify-content: center;
}
