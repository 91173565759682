.banner-layout {
  background: url("../../img/bannerBackground.jpg") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hide {
  visibility: hidden;
}

.banner-body {
  font-family: "Playfair Display";
  margin: 20px 400px;
  font-weight: 500;
  color: #d4b8b8;
  width: 60%;
  display: flex;
  flex-direction: column;
}

.banner-button {
  cursor: pointer;
  /* margin: 190px 0 0; */
  display: flex;
  color: #d4b8b8;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
  /* border: 1px solid white; */
  /* border-radius: 100%; */
  width: 100px;
  font-size: 40px;
  height: 100px;
}

.cusom {
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.bb-text {
  font-size: 50px;
}

.bb-text-highlight {
  display: inline-flex;
  color: #e7d3c1d6;
  font-size: 80px;
}

.bb-subtext-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bb-subtext {
  font-size: 40px;
}

.banner-description {
  font-family: "Raleway";
  margin: 20px 260px;
  font-size: 18px;
  color: #d4b8b8;
}

@media only screen and (max-width: 600px) {
  .banner-body {
    margin: 25px 50px;
    font-weight: 300;
    width: 90%;
    padding: 30px 0 0 0;
  }

  .banner-description {
    margin: 25px 50px;
    font-size: 18px;
    color: #d4b8b8;
  }

  .bb-text-highlight {
    font-size: 60px;
  }

  .bb-text {
    font-size: 35px;
  }

  .bb-subtext {
    font-size: 25px;
  }

  .banner-layout {
    height: 90vh;
  }
}

@media only screen and (max-width: 350px) {
  .banner-body {
    margin: 25px 50px;
    font-weight: 300;
    width: 90%;
    padding: 120px 0 0 0;
  }
}
