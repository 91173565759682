.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.skills {
  color: grey;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hidden {
  display: none;
}

.cursorBlink {
  font-weight: 300;
  font-size: 30px;
  color: white;
  width: 5px;
  overflow: hidden;
  animation: blink 1s steps(5, start) infinite;
}

@keyframes blink {
  from,
  to { color: transparent; }
  50% { color: #fff; }
}

@-moz-keyframes blink {
  from,
  to { color: transparent; }
  50% { color: #fff; }
}

@-webkit-keyframes blink {
  from,
  to { color: transparent; }
  50% { color: #fff; }
}

@-ms-keyframes blink {
  from,
  to { color: transparent; }
  50% { color: #fff; }
}

@-o-keyframes blink {
  from,
  to { color: transparent; }
  50% { color: #fff; }
}

.image {
  width: 140px;
  height: 140px;
  border: 1px solid black;
  border-radius: 77px;
}
