.footer-container {
  color: white;
  font-family: "Raleway";
}

@media only screen and (max-width: 600px) {
  .footer-container {
    padding: 0 10px 2px 10px;
  }
}
