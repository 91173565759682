.signature-img {
  width: 160px;
  height: 60px;
}

.container {
  position: absolute;
  top: 10px;
  left: 0;
  color: white;
  z-index: 1;
  padding: 10px 20px;
}

@media only screen and (max-width: 600px) {
  .signature-img {
    width: 120px;
    height: 40px;
  }
}
