.container {
  background-color: black;
  text-align: center;
}

.profile {
  transition: transform 1.2s;
  transform-style: preserve-3d;
  perspective: 1000px;
}
