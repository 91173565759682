@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Raleway);
* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow-x: hidden;
}

.Typist .Cursor {
  display: inline-block;
}

.Typist .Cursor--blinking {
  opacity: 1;
  -webkit-animation: blink 1s linear infinite;
          animation: blink 1s linear infinite;
}

.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.skills {
  color: grey;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.hidden {
  display: none;
}

.cursorBlink {
  font-weight: 300;
  font-size: 30px;
  color: white;
  width: 5px;
  overflow: hidden;
  -webkit-animation: blink 1s steps(5, start) infinite;
          animation: blink 1s steps(5, start) infinite;
}

@keyframes blink {
  from,
  to { color: transparent; }
  50% { color: #fff; }
}

@-webkit-keyframes blink {
  from,
  to { color: transparent; }
  50% { color: #fff; }
}

.image {
  width: 140px;
  height: 140px;
  border: 1px solid black;
  border-radius: 77px;
}

.Banner_banner-layout__3jK6g {
  background: url(/static/media/bannerBackground.b5a69cbd.jpg) no-repeat center center
    fixed;
  background-size: cover;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Banner_hide__3yQaI {
  visibility: hidden;
}

.Banner_banner-body__vOg2w {
  font-family: "Playfair Display";
  margin: 20px 400px;
  font-weight: 500;
  color: #d4b8b8;
  width: 60%;
  display: flex;
  flex-direction: column;
}

.Banner_banner-button__tSq46 {
  cursor: pointer;
  /* margin: 190px 0 0; */
  display: flex;
  color: #d4b8b8;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
  /* border: 1px solid white; */
  /* border-radius: 100%; */
  width: 100px;
  font-size: 40px;
  height: 100px;
}

.Banner_cusom__lZJIE {
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes Banner_fadeIn__3Z4Xw {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Banner_fadeIn__3Z4Xw {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Banner_fadeIn__3Z4Xw {
  -webkit-animation-name: Banner_fadeIn__3Z4Xw;
  animation-name: Banner_fadeIn__3Z4Xw;
}

.Banner_bb-text__J0bH1 {
  font-size: 50px;
}

.Banner_bb-text-highlight__1dzPK {
  display: inline-flex;
  color: #e7d3c1d6;
  font-size: 80px;
}

.Banner_bb-subtext-container__o1k4p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Banner_bb-subtext__nmQm5 {
  font-size: 40px;
}

.Banner_banner-description__3PT3W {
  font-family: "Raleway";
  margin: 20px 260px;
  font-size: 18px;
  color: #d4b8b8;
}

@media only screen and (max-width: 600px) {
  .Banner_banner-body__vOg2w {
    margin: 25px 50px;
    font-weight: 300;
    width: 90%;
    padding: 30px 0 0 0;
  }

  .Banner_banner-description__3PT3W {
    margin: 25px 50px;
    font-size: 18px;
    color: #d4b8b8;
  }

  .Banner_bb-text-highlight__1dzPK {
    font-size: 60px;
  }

  .Banner_bb-text__J0bH1 {
    font-size: 35px;
  }

  .Banner_bb-subtext__nmQm5 {
    font-size: 25px;
  }

  .Banner_banner-layout__3jK6g {
    height: 90vh;
  }
}

@media only screen and (max-width: 350px) {
  .Banner_banner-body__vOg2w {
    margin: 25px 50px;
    font-weight: 300;
    width: 90%;
    padding: 120px 0 0 0;
  }
}

.Projects_img__1LepN {
  width: 100%;
  height: 290px;
  object-fit: contain;
}

.Projects_box-container__3n5Kn {
  display: flex;
  flex-direction: row;
  /* margin: 20px 400px; */
}

.Projects_img-container__3ybPW {
  position: relative;
  display: flex;
  width: 50%;
  z-index: 0;
}

.Projects_img-wrapper__1gRK_ {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.Projects_img-wrapper__1gRK_:hover {
  background-color: transparent;
}

.Projects_description__2zJDE {
  width: 50%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: right;
  z-index: 1;
}

.Projects_title__FCsvv {
  font-size: 25px;
  padding: 10px;
  border-top: 2px solid rgb(100 255 218 / 54%);
}

.Projects_summary__16IsT {
  position: absolute;
  /* right: 10%; */
  top: 20%;
  /* background-color: #112240; */
  background-color: rgb(22 33 64 / 82%);
  height: 40%;
  width: 120%;
  padding: 10px;
  font-size: 14px;
  /* font-family: monospace; */
}

.Projects_summary-align-normal__1duCl {
  align-self: flex-start;
  text-align: right;
  left: -20%;
}

.Projects_summary-align-flipped__15MSf {
  align-self: flex-end;
  text-align: left;
  right: -20%;
}

.Projects_tech__wV7AF {
  margin: 40% 1% 5% 5%;
  font-family: monospace;
  flex-flow: wrap;
  display: flex;
  color: #d4b8b8;
}

.Projects_tech-align-normal__2YMgN {
  justify-content: flex-end;
}

.Projects_tech-align-flipped__C2Q8M {
  justify-content: flex-start;
}

.Projects_tech-item__28rot {
  margin: 2px 5px;
}

.Projects_links__1L2-2 {
  display: flex;
  margin: 5% 1% 5% 5%;
  cursor: pointer;
}

.Projects_links-align-normal__YyJhB {
  justify-content: flex-end;
}

.Projects_links-align-flipped__vsamd {
  justify-content: flex-start;
}

@media only screen and (max-width: 600px) {
  .Projects_box-container__3n5Kn {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Projects_description__2zJDE {
    width: auto;
    margin: 20px 50px;
    /* background-color: #09182d; */
    background-color: rgb(13 21 44 / 80%);
  }

  .Projects_img-container__3ybPW {
    display: none;
  }

  .Projects_summary__16IsT {
    position: static;
    width: 90%;
  }

  .Projects_tech__wV7AF {
    margin: 5% 10%;
  }

  .Projects_links__1L2-2 {
    margin: 10% 10% 10% 10%;
  }
}

.Body_body-container__2Wk4C {
  height: auto;
  width: 100vw;
  background-color: #02081b;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.Body_body-picture__108Mu {
  display: flex;
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
}

.Body_body-picture-hexagon__nVQ_- {
  display: flex;
  position: relative;
  width: 240px;
  height: 265px;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  background: url(/static/media/profile15.0133e281.jpg) no-repeat;
  background-size: 100% 100%;
}

.Body_body-text-intro__2WnjU {
  font-family: "Raleway";
  margin: 20px 400px;
}

.Body_body-text__G8Qeu {
  display: flex;
  flex-direction: column;
  color: white;
  height: 100%;
  background-color: black;
  width: 100%;
  font-family: "Open Sans";
  font-size: 18px;
  align-items: center;
}

.Body_body-text-title__wwQ4K {
  color: green;
}

.Body_skill-container-outer__3nJss {
  margin: 20px 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}

.Body_skill-container__3WRbj {
  display: flex;
  width: auto;
  list-style: none;
}

.Body_skill__4RvQs {
  display: flex;
  justify-content: flex-start;
  margin: 2px;
}

.Body_skill-ul__1LZiS {
  list-style: none;
}

li::before {
  content: "▹";
  padding: 0 12px;
  color: rgb(100, 255, 218);
}

@media only screen and (max-width: 600px) {
  .Body_body-text-intro__2WnjU {
    margin: 20px 50px;
  }

  .Body_skill-container-outer__3nJss {
    margin: 20px 45px 20px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
  }

  .Body_skill-container__3WRbj {
    justify-content: center;
    width: 158px;
  }

  .Body_body-picture-hexagon__nVQ_- {
    width: 180px;
    height: 215px;
  }
}

.Body_body-wrapper__6uTsf {
  display: flex;
  justify-content: center;
}

.Footer_footer-container__C6OIF {
  color: white;
  font-family: "Raleway";
}

@media only screen and (max-width: 600px) {
  .Footer_footer-container__C6OIF {
    padding: 0 10px 2px 10px;
  }
}

.TopBar_signature-img__3bCPM {
  width: 160px;
  height: 60px;
}

.TopBar_container__2-KQA {
  position: absolute;
  top: 10px;
  left: 0;
  color: white;
  z-index: 1;
  padding: 10px 20px;
}

@media only screen and (max-width: 600px) {
  .TopBar_signature-img__3bCPM {
    width: 120px;
    height: 40px;
  }
}

.Contact_contact-container__jbNo4 {
  color: white;
  display: flex;
  height: 400px;
  width: 100%;
  flex-direction: column;
  background-color: black;
  justify-content: center;
}

.Contact_contact-icon-container__3KH8R {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  margin: 20px 10%;
}

.Contact_contact__1m2re {
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 240px;
  height: 265px;
}

.Contact_contact__1m2re a {
  color: rgb(100, 255, 218);
}

.Contact_contact-subtext__3_vfK {
  font-family: "Raleway";
  margin: 0 30%;
}

@media only screen and (max-width: 600px) {
  .Contact_contact__1m2re {
    height: 100px;
  }

  .Contact_contact-container__jbNo4 {
    height: auto;
  }
}

.Main_container__3Tx6w {
  background-color: black;
  text-align: center;
}

.Main_profile__3QmWn {
  transition: -webkit-transform 1.2s;
  transition: transform 1.2s;
  transition: transform 1.2s, -webkit-transform 1.2s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

