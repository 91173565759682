.contact-container {
  color: white;
  display: flex;
  height: 400px;
  width: 100%;
  flex-direction: column;
  background-color: black;
  justify-content: center;
}

.contact-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  margin: 20px 10%;
}

.contact {
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 240px;
  height: 265px;
}

.contact a {
  color: rgb(100, 255, 218);
}

.contact-subtext {
  font-family: "Raleway";
  margin: 0 30%;
}

@media only screen and (max-width: 600px) {
  .contact {
    height: 100px;
  }

  .contact-container {
    height: auto;
  }
}
