.img {
  width: 100%;
  height: 290px;
  object-fit: contain;
}

.box-container {
  display: flex;
  flex-direction: row;
  /* margin: 20px 400px; */
}

.img-container {
  position: relative;
  display: flex;
  width: 50%;
  z-index: 0;
}

.img-wrapper {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.img-wrapper:hover {
  background-color: transparent;
}

.description {
  width: 50%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: right;
  z-index: 1;
}

.title {
  font-size: 25px;
  padding: 10px;
  border-top: 2px solid rgb(100 255 218 / 54%);
}

.summary {
  position: absolute;
  /* right: 10%; */
  top: 20%;
  /* background-color: #112240; */
  background-color: rgb(22 33 64 / 82%);
  height: 40%;
  width: 120%;
  padding: 10px;
  font-size: 14px;
  /* font-family: monospace; */
}

.summary-align-normal {
  align-self: flex-start;
  text-align: right;
  left: -20%;
}

.summary-align-flipped {
  align-self: flex-end;
  text-align: left;
  right: -20%;
}

.tech {
  margin: 40% 1% 5% 5%;
  font-family: monospace;
  flex-flow: wrap;
  display: flex;
  color: #d4b8b8;
}

.tech-align-normal {
  justify-content: flex-end;
}

.tech-align-flipped {
  justify-content: flex-start;
}

.tech-item {
  margin: 2px 5px;
}

.links {
  display: flex;
  margin: 5% 1% 5% 5%;
  cursor: pointer;
}

.links-align-normal {
  justify-content: flex-end;
}

.links-align-flipped {
  justify-content: flex-start;
}

@media only screen and (max-width: 600px) {
  .box-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .description {
    width: auto;
    margin: 20px 50px;
    /* background-color: #09182d; */
    background-color: rgb(13 21 44 / 80%);
  }

  .img-container {
    display: none;
  }

  .summary {
    position: static;
    width: 90%;
  }

  .tech {
    margin: 5% 10%;
  }

  .links {
    margin: 10% 10% 10% 10%;
  }
}
